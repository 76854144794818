/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    a: "a"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "El siguiente paso lógico a seguir después apostar por el SHL, es respaldar las redes libres, abiertas y neutras. La red libre es necesaria, en estos últimos años a dejado de concebirse cualquier sistema tecnológico que no esté conectado a una red, por eso si vamos a diseñar sistemas libres también deben conectarse entre ellos libremente. Pongamos un ejemplo; el proyecto OLPC intenta proporcionar portátiles a todos los niños en paises en vias de desarrollo, su primera versión de portátil de bajo coste utiliazaba Software Libre, la segunda está en camino y tendrá Hardware Libre, esto beneficiará sobretodo en la facilidad de fabricación y el abaratamiento de costes, pero si no ofrecemos una Red Libre donde conectar estos ordenadores y por lo tanto sus usuarios,seguiremos teniendo un gran problema."), "\n", React.createElement(_components.p, null, "Volviendo al tema, ", React.createElement(_components.a, {
    href: "http://www.redlibre.net/",
    title: "redlibre"
  }, "redlibre"), " es un conjunto grupos que promueven las redes libres en españa, la mas importante de ellas ", React.createElement(_components.a, {
    href: "http://guifi.net/",
    title: "Güifi.net"
  }, "Güifi.net"), " que actúa sobretodo en Cataluña, ellos se basan en la ”", React.createElement(_components.a, {
    href: "http://www.guifi.net/WirelessCommonsES",
    title: "Licencia Procomún Inalámbrica"
  }, "Licencia Procomún Inalámbrica"), "” para construir una gran red, sin perder de vista sus objetivos."), "\n", React.createElement(_components.p, null, "Uniendo estos tres pilares fundamentales, Software, Hardware & Net Libre podemos sentar las bases de una cultura libre. Pero esto no es el final, poco a poco podemos ir liberando todas las tecnologias que hasta ahora estan en poder de las grandes empresas, por eso el camino continua hacia opciones como,redes de fibra optica libre, telefonía libre, auto-producción y todo lo que podamos imaginar."), "\n", React.createElement(_components.p, null, "Seguiremos investigando."));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
